import React from 'react';

import { faBarsStaggered, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../../components/Icon';
import AccountDropdown from './AccountDropdown';

import classNames from 'classnames';
import { debounce } from 'lodash';

interface Props {
  setOpen: (value: boolean) => void;
  searchEnabled?: boolean;
  searchPlaceholder?: string;
  onSearch?: (query: string) => void;

  className?: string;
  inputClassName?: string;
  dropdownClassName?: string;
}

export default function Navbar({ setOpen, searchEnabled, searchPlaceholder, onSearch, className, inputClassName, dropdownClassName }: Props) {
  return (
    <div className={classNames('sticky top-0 z-10 shrink-0 flex h-16 bg-white border-y border-gray-200', className)}>
      <button
        type="button"
        className="w-16 px-4 border-r border-gray-200 focus:outline-none md:hidden"
        onClick={() => setOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Icon icon={faBarsStaggered} className="h-5 w-5 mt-2" />
      </button>

      <div className="flex-1 px-4 flex justify-end">
        {searchEnabled && (
          <div className="flex flex-1">
            <div className="flex w-full md:ml-0">
              <label htmlFor="navbar-search-input" className="sr-only">
                {searchPlaceholder}
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                  <Icon icon={faMagnifyingGlass} className="h-4 w-4" />
                </div>
                <input
                  id="navbar-search-input"
                  className={classNames('block h-full w-full bg-transparent border-transparent py-2 pl-7 pr-3 text-gray-900 placeholder:text-gray-500 focus:border-transparent focus:placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm', inputClassName)}
                  placeholder={searchPlaceholder}
                  type="search"
                  onChange={debounce((event) => {
                    if (onSearch) { onSearch(event.target.value); }
                  }, 300)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="ml-4 flex items-center md:ml-6">
          <AccountDropdown className={dropdownClassName} />
        </div>
      </div>
    </div>
  );
}
