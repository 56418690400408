import React from 'react';

import { Country } from '../../../lib/graphql/entities/country';

import { AddressAutofill as MapboxAddressAutofill } from '@mapbox/search-js-react';
import { AddressAutofillFeatureSuggestion as MapboxFeature } from '@mapbox/search-js-core';

import { ENVIRONMENT } from '../../../lib/config';

interface Props {
  accessToken: string;
  country?: Country;
  onSelect: (feature: MapboxFeature) => void;

  children: React.ReactNode;
}

export default function AddressAutofill({ accessToken, country, onSelect, children }: Props) {
  // Disable in test environments since it crashes.
  if (ENVIRONMENT === 'test') {
    return children as React.ReactElement;
  }

  return (
    <MapboxAddressAutofill
      accessToken={accessToken}
      options={{ country }}
      onRetrieve={({ features }) => {
        onSelect(features[0]);
      }}
    >
      {children as React.ReactElement}
    </MapboxAddressAutofill>
  );
}
