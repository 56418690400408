import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { User } from './entities/user';
import { MYPRICES_GRAPHQL_ENDPOINT } from '../config';

import { toast } from 'react-hot-toast';

import { Crisp } from 'crisp-sdk-web';
import { identify as identifyCrisp } from '../crisp';

export const onSignIn = (user: User, authenticationToken: string) => {
  identifyCrisp(user);

  return localStorage.setItem('bearer-token', `${user.id}:${authenticationToken}`);
};

export const getBearerToken = () => {
  return localStorage.getItem('bearer-token');
};

const removeBearerToken = () => {
  return localStorage.removeItem('bearer-token');
};

const cache = new InMemoryCache({
  typePolicies: {
    Pexels: { merge: true },
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message }) => {
      if (extensions?.code) { toast.error(message); }
    });
  }

  if (networkError) {
    toast.error('Oops! Something\'s wrong with our server. Please check back later.');
  }
});

const httpLink = createUploadLink({
  uri: MYPRICES_GRAPHQL_ENDPOINT,
  fetch(uri, options) {
    if (getBearerToken()) {
      options!.headers = {
        ...options!.headers,
        authorization: `Bearer ${window.btoa(getBearerToken()!)}`,
      };
    }

    return fetch(uri, options);
  },
});

const client = new ApolloClient({
  cache,
  link: from([errorLink, httpLink]),
});

client.onResetStore(async () => {
  // Resets Crisp session, if fails, tries again every second.
  const resetCrisp = () => {
    try {
      Crisp.session.reset();
    } catch {
      setTimeout(resetCrisp, 1000);
    }
  };

  // Reset Crisp trigger first.
  Crisp.session.onLoaded(() => null);

  // Reset Crisp session.
  resetCrisp();

  // Finally, remove bearer token.
  removeBearerToken();
});

export default client;
