import React, { useState } from 'react';
import { useDebounce } from 'react-use';

import { gql, useLazyQuery } from '@apollo/client';
import { Query } from '../../../../../lib/graphql/entities/query';
import { UNSPLASH_PHOTO_FIELDS } from '../../../../../lib/graphql/fragments/unsplash/photo';

import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import InputField from '../../../InputField';
import EmptyState from '../../../../EmptyState';
import Icon from '../../../../Icon';
import Loading from '../../../../Loading';
import Photo from './Photo';

import { MediaValue } from '../../';

export const SEARCH_UNSPLASH = gql`
  ${UNSPLASH_PHOTO_FIELDS}

  query SearchUnsplash($query: String!) {
    unsplash {
      photos(query: $query) {
        ...UnsplashPhotoFields
      }
    }
  }
`;

export interface SearchUnsplashVariables {
  query: string;
}

interface Props {
  selected?: string | null;
  onChange: (state: { selected: string; value: MediaValue }) => void;
  reset: () => void;
}

export default function StockPhotoTab({ selected, onChange, reset }: Props) {
  const [searchUnsplash, { data }] = useLazyQuery<Query, SearchUnsplashVariables>(SEARCH_UNSPLASH);
  const photos = data?.unsplash?.photos || [];

  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');

  // Search the Unsplash API when the query changes.
  useDebounce(() => {
    if (query.trim() === '') { return; }

    searchUnsplash({ variables: { query } })
      .then(() => setSearching(false));
  }, 300, [query, searchUnsplash]);

  return (
    <div className="space-y-2">
      <div>
        <InputField
          placeholder="Search photos..."
          icon={faMagnifyingGlass}
          autoFocus
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            setSearching(true);

            // Reset the state when the query changes.
            reset();
          }}
          data-testid="search-input"
        />
      </div>

      <div className="border border-gray-200 h-96 sm:h-72 rounded-lg overflow-y-scroll">
        {(() => {
          if (query === '') {
            return (
              <EmptyState
                topContent={(
                  <Icon
                    className="text-gray-400"
                    icon={faMagnifyingGlass}
                    size="2x"
                  />
                )}
                title="Search for anything!"
                text={(
                  <span>
                    Powered by <a href="https://unsplash.com?utm_source=MyPrices&utm_medium=referral" target="_blank" rel="noreferrer" className="text-black font-medium">Unsplash</a>.
                  </span>
                )}
                data-testid="no-query-state"
              />
            );
          }

          if (searching) {
            return (
              <Loading
                text={`Searching for "${query}"`}
                size={28}
                className="h-full"
                data-testid="loading-state"
              />
            );
          }

          if (photos.length === 0) {
            return (
              <EmptyState
                topContent={(
                  <Icon
                    className="text-gray-400"
                    icon={faMagnifyingGlass}
                    size="2x"
                  />
                )}
                title={`No photos found for "${query}"`}
                text="Try a different search term."
                data-testid="no-results-state"
              />
            );
          }

          return (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 p-2">
              {photos.map((photo) => (
                <Photo
                  key={photo.id}
                  photo={photo}
                  selected={selected === photo.id}
                  onSelect={(photo) => {
                    onChange({
                      selected: photo.id,
                      value: {
                        type: 'IMAGE',
                        url: photo.source,
                      },
                    });
                  }}
                />
              ))}
            </div>
          );
        })()}
      </div>
    </div>
  );
}
