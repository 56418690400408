import React from 'react';

import { ThemeFile } from '../../../../lib/graphql/entities/theme/file';
import { ThemeFileFolder } from '../CodeEditorPage';

import { useNavigate } from 'react-router-dom';
import paths from '../../../../paths';

import {
  faArrowLeft, faFileCirclePlus, faImage, faVideo, faCode,
} from '@fortawesome/pro-regular-svg-icons';
import Icon, { IconProp } from '../../../../components/Icon';
import Button from '../../../../components/Button';
import EmptyState from '../../../../components/EmptyState';

import { isEmpty, sortBy } from 'lodash';
import { singular } from 'pluralize';
import classNames from 'classnames';

interface SideMenuProps {
  files: ThemeFile[];
  query?: string;
  activeFile: ThemeFile | undefined;
  setActiveFile: (file: ThemeFile) => void;
  onClickCreateFile: (folder: ThemeFileFolder) => void;
}

export default function SideMenu({ files, query, activeFile, setActiveFile, onClickCreateFile }: SideMenuProps) {
  const navigate = useNavigate();

  files = files.filter((file) => {
    if (query == null) { return true; }
    return file.key.toLowerCase().includes(query.toLowerCase());
  });

  const filterByFolder = (folder: ThemeFileFolder) => {
    const filtered = files.filter((file) => file.key.startsWith(folder + '/'));
    return sortBy(filtered, (file) => file.key);
  };

  const configFiles = filterByFolder('config');
  const layoutFiles = filterByFolder('layouts');
  const templateFiles = filterByFolder('templates');
  const sectionFiles = filterByFolder('sections');
  const snippetFiles = filterByFolder('snippets');
  const assetFiles = filterByFolder('assets');

  const sections = [
    { name: 'Config', folder: 'config' as ThemeFileFolder, files: configFiles },
    { name: 'Layouts', folder: 'layouts' as ThemeFileFolder, files: layoutFiles },
    { name: 'Templates', folder: 'templates' as ThemeFileFolder, files: templateFiles },
    { name: 'Sections', folder: 'sections' as ThemeFileFolder, files: sectionFiles },
    { name: 'Snippets', folder: 'snippets' as ThemeFileFolder, files: snippetFiles },
    { name: 'Assets', folder: 'assets' as ThemeFileFolder, files: assetFiles },
  ].filter((section) => {
    // If there is a query, only show sections with matching files.
    if (!isEmpty(query) && section.files.length === 0) { return false; }

    return true;
  });

  return (
    <div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-y-0 z-20">
      <div className="flex flex-col grow pt-8 bg-black shadow-2xl overflow-y-auto">
        <div className="flex items-center shrink-0 px-6">
          <Button color="gray-dark" rounded={true} leftIcon={faArrowLeft} onClick={() => navigate(paths.dashboard)}>
            Dashboard
          </Button>
        </div>
        <div className="mt-5 grow flex flex-col">
          <nav className="flex-1 px-2 pb-2 space-y-5">
            {!!query && sections.length === 0 && (
              <EmptyState
                data-testid="no-matching-files-message"
                innerClassName="overflow-hidden break-words"
                title={<span className="text-white">Nothing here...</span>}
                text={<span>Couldn't find any files matching "{query}".</span>}
              />
            )}

            {sections.map((section, index) => (
              <div key={index} className="space-y-2">
                <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                  {section.name}
                </h3>

                <div className="space-y-1" role="group">
                  {!query && section.folder !== 'config' && (
                    <Item
                      icon={faFileCirclePlus}
                      className="!py-2"
                      onClick={() => onClickCreateFile(section.folder)}
                    >
                      <span className="font-sans">Add a new {singular(section.folder)}</span>
                    </Item>
                  )}

                  {section.files.map((file, index) => (
                    <FileItem
                      key={index}
                      onClick={() => setActiveFile(file)}
                      themeFile={file}
                      active={activeFile?.id === file.id}
                    />
                  ))}
                </div>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

interface ItemProps {
  onClick?: () => void;
  active?: boolean;

  className?: string;
  icon?: IconProp;
}

function Item({ onClick, active, className, icon, children }: React.PropsWithChildren<ItemProps>) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'cursor-pointer group flex items-center px-3 py-1 font-medium font-mono',
        'rounded-md text-gray-400 hover:bg-zinc-900 text-base md:text-sm',
        className,
        {
          'bg-zinc-900 !text-white': active,
        },
      )}
    >
      {icon && (
        <Icon
          icon={icon}
          className={classNames(
            'shrink-0 h-5 w-5 mr-4 md:mr-3 text-gray-400',
            { '!text-white': active },
          )}
        />
      )}

      {children}
    </div>
  );
}

interface FileItemProps extends ItemProps {
  themeFile: ThemeFile;
}

function FileItem({ themeFile, ...props }: FileItemProps) {
  const name = themeFile.key.split('/').pop();
  const extension = themeFile.key.split('.').pop();

  const icon = (() => {
    switch (extension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'webp':
        return faImage;
      case 'mp4':
      case 'webm':
        return faVideo;
      default:
        return faCode;
    }
  })();

  return (
    <Item icon={icon} {...props}>
      <span>{name}</span>
    </Item>
  );
}
