import React from 'react';

import { Country } from '../../../lib/graphql/entities/country';

import { useFormContext } from 'react-hook-form';
import AddressAutofill from './AddressAutofill';
import InputField from '../InputField';
import CityField from './CityField';
import RegionField, { COUNTRIES_WITHOUT_REGION_INPUT } from './RegionField';
import PostalCodeField from './PostalCodeField';

import classNames from 'classnames';
import { MAPBOX_TOKEN } from '../../../lib/config';

export interface AddressValues {
  line1: string;
  line2: string | null;
  city: string;
  region: string;
  postalCode: string;
}

interface Props {
  name: string;
  country: Country;
  disabled?: boolean;
  gridClassName?: string;
}

export default function AddressFields({ name, country, disabled, gridClassName }: Props) {
  const { clearErrors, setValue, register, formState } = useFormContext<Record<string, AddressValues | null>>();

  return (
    <React.Fragment>
      <div data-testid="address-inputs" data-disabled={disabled} />

      <div>
        <AddressAutofill
          accessToken={MAPBOX_TOKEN}
          country={country}
          onSelect={(feature) => {
            // Clear any prior address errors.
            clearErrors(name);

            // Set the address fields.
            setValue(name, {
              line1: feature.properties.address_line1 || '',
              line2: '',
              city: (feature.properties as any).place,
              region: (feature.properties as any).region_code,
              postalCode: feature.properties.postcode || '',
            });
          }}
        >
          <InputField
            label="Address"
            placeholder="123 Main Street"
            {...register(`${name}.line1`)}
            type="text"
            autoComplete="address-line1"
            error={formState.errors[name]?.line1?.message}
            disabled={disabled}
            data-testid="address-line1-input"
          />
        </AddressAutofill>
      </div>

      <InputField
        label="Unit, suite, etc."
        placeholder="Suite 1000"
        {...register(`${name}.line2`)}
        type="text"
        autoComplete="address-line2"
        error={formState.errors[name]?.line2?.message}
        disabled={disabled}
        data-testid="address-line2-input"
      />

      <div
        className={classNames(
          'grid grid-cols-1 sm:gap-x-4',
          gridClassName,
          COUNTRIES_WITHOUT_REGION_INPUT.includes(country) ? 'sm:grid-cols-2' : 'sm:grid-cols-3',
        )}
      >
        <CityField
          country={country}
          {...register(`${name}.city`)}
          error={formState.errors[name]?.city?.message}
          disabled={disabled}
          data-testid="address-city-input"
        />

        {(() => {
          if (COUNTRIES_WITHOUT_REGION_INPUT.includes(country)) {
            return <input type="hidden" {...register(`${name}.region`)} />;
          }

          return (
            <RegionField
              country={country}
              {...register(`${name}.region`)}
              error={formState.errors[name]?.region?.message}
              disabled={disabled}
              data-testid="address-region-input"
            />
          );
        })()}

        <PostalCodeField
          country={country}
          {...register(`${name}.postalCode`)}
          autoComplete="postal-code"
          error={formState.errors[name]?.postalCode?.message}
          disabled={disabled}
          data-testid="address-postal-code-input"
        />
      </div>
    </React.Fragment>
  );
}
