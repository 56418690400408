import React, { useState, useCallback, useEffect, useRef } from 'react';

import { ApolloCache, useMutation } from '@apollo/client';
import {
  SIGN_UP_OAUTH,
  Data as SignUpOAuthData,
  Variables as SignUpOAuthVariables,
} from '../../../lib/graphql/mutations/auth/sign-up-oauth';
import {
  SIGN_UP,
  Data as SignUpData,
  Variables as SignUpVariables,
} from '../../../lib/graphql/mutations/auth/sign-up';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/entities/query';
import { User, AuthenticationProvider } from '../../../lib/graphql/entities/user';
import { onSignIn } from '../../../lib/graphql/client';

import { Link, useNavigate } from 'react-router-dom';
import paths from '../../../paths';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Helmet } from 'react-helmet';
import Logo from '../../../components/layout/Logo';
import InputField from '../../../components/fields/InputField';
import Switch from '../../../components/fields/Switch';
import Button from '../../../components/Button';
import OAuthTermsAgreementModal from './components/OAuthTermsAgreementModal';

import { GOOGLE_OAUTH_CLIENT_ID } from '../../../lib/config';

interface FormValues {
  email: string;
  password: string;
  agreesToTerms: boolean;
}

interface OAuthAgreementModalState {
  provider: AuthenticationProvider;
  code: string;
}

export default function SignUpPage() {
  const navigate = useNavigate();

  const [oauthTermsAgreementModalState, setOAuthTermsAgreementModalState] = useState<OAuthAgreementModalState>();

  const didAuthenticate = useCallback((cache: ApolloCache<any>, { user, authenticationToken }: { user: User; authenticationToken: string; }) => {
    // Update the cache with the authenticated user
    cache.writeQuery<Query>({
      query: GET_INITIAL_DATA,
      data: { viewer: user },
    });

    onSignIn(user, authenticationToken);
    navigate(paths.onboarding.selectPlan);
  }, [navigate]);

  // Get the user's time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Initialize OAuth buttons
  const [oauth, { loading: oauthProcessing }] = useMutation<SignUpOAuthData, SignUpOAuthVariables>(SIGN_UP_OAUTH);
  const oauthContainerRef = useRef<HTMLDivElement>(null);
  const signInWithGoogleContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Google
    google.accounts.id.initialize({
      client_id: GOOGLE_OAUTH_CLIENT_ID,
      callback: (response: any) => {
        setOAuthTermsAgreementModalState({
          provider: 'GOOGLE',
          code: response.credential,
        });
      },
    });

    google.accounts.id.renderButton(signInWithGoogleContainerRef.current!, {
      theme: 'outline',
      text: 'signup_with',
      width: oauthContainerRef.current!.offsetWidth,
    });
  }, [oauth, didAuthenticate, timeZone]);

  const [signUp] = useMutation<SignUpData, SignUpVariables>(SIGN_UP);
  const { handleSubmit, register, formState, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      agreesToTerms: false,
    },
    resolver: zodResolver(z.object({
      email: z.string().min(1, { message: 'Please enter your email.' }).email({ message: 'Must be a valid email.' }),
      password: z.string().min(1, { message: 'Please create a password.' }),
      agreesToTerms: z.boolean().refine((value) => value === true, {
        message: 'You must agree to the Terms of Service to create an account.',
      }),
    })),
  });

  const onSubmit = async (input: FormValues) => {
    await signUp({
      variables: { input: { ...input, timeZone } },
      update: (cache, { data }) => {
        const { user, authenticationToken } = data!.authSignUp;
        didAuthenticate(cache, { user, authenticationToken });
      },
      onError: () => null,
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign up</title>
      </Helmet>

      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Logo to={paths.auth.signUp} />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Create an account</h2>
              <p className="mt-2 text-sm text-gray-600">
                Or
                {' '}
                <Link to={paths.auth.signIn} className="font-medium text-black hover:text-neutral-900">
                  log in to an existing account
                </Link>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div className="relative h-11" ref={oauthContainerRef}>
                  <div className="google-sign-in absolute inset-0" ref={signInWithGoogleContainerRef} />
                </div>

                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                  <InputField
                    label="Email"
                    placeholder="you@example.com"
                    {...register('email')}
                    type="email"
                    autoComplete="email"
                    required
                    error={formState.errors.email?.message}
                  />

                  <InputField
                    label="Password"
                    placeholder="Create a password"
                    {...register('password')}
                    type="password"
                    autoComplete="new-password"
                    required
                    error={formState.errors.password?.message}
                  />

                  <Switch
                    id="agrees-to-terms"
                    checked={watch('agreesToTerms')}
                    onToggle={(checked) => setValue('agreesToTerms', checked, { shouldValidate: true })}
                    error={formState.errors.agreesToTerms?.message}
                    data-testid="agrees-to-terms-switch"
                  >
                    <p className="text-xs text-gray-500">
                      I agree to the <a href="https://myprices.io/policies/terms" target="_blank" rel="noopener noreferrer" className="font-medium text-black hover:text-neutral-900">Terms of Service</a>,
                      and agree to the processing of my data as described in the <a href="https://myprices.io/policies/privacy" target="_blank" rel="noopener noreferrer" className="font-medium text-black hover:text-neutral-900">Privacy Policy</a>.
                    </p>
                  </Switch>

                  <Button type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting} fullWidth>
                    Sign up
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1637613345105-e6e721ffa9d4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt=""
          />
        </div>
      </div>

      <OAuthTermsAgreementModal
        open={oauthTermsAgreementModalState != null}
        onClose={() => setOAuthTermsAgreementModalState(undefined)}
        onConfirm={async () => {
          const { provider, code } = oauthTermsAgreementModalState!;

          await oauth({
            variables: { input: { provider, code, timeZone, agreesToTerms: true } },
            update: (cache, { data }) => {
              const { user, authenticationToken } = data!.authSignUpOAuth;
              didAuthenticate(cache, { user, authenticationToken });
            },
            onError: () => setOAuthTermsAgreementModalState(undefined),
          });
        }}
        processing={oauthProcessing}
      />
    </React.Fragment>
  );
}
